import { clsMerge } from "@artifactlabs/shared-react-utils";
import { humanFileSize, useTypeToReadableMapping } from "@artifactlabs/shared-utils";
import { startCase } from "lodash";
import { Duration } from "luxon";
import { useState } from "react";

import { getResolutionLabel, getResolutionContent } from "@/utils/artworks";
import { poppins } from "@/utils/font";

interface Props {
  row: any;
}

export const RequestMetadata = ({ row }: Props) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [showMetaData, setShowMetaData] = useState<boolean>(() => {
    return !window.location.host.includes("usn") && !window.location.host.includes("platform");
  });

  const assetDetails = row?.artwork?.assets?.find(
    (asset: any) => asset?.size === row?.conditions?.resolution,
  );

  return (
    <table className={clsMerge("text-sm text-[#87888C]", poppins.className)}>
      <tbody>
        <tr>
          <td>Use type</td>
          <td className="font-medium">{useTypeToReadableMapping[row?.conditions?.use]}</td>
        </tr>
        <tr>
          <td>{getResolutionLabel(row?.artwork?.metadata?.assetFileFormat)}</td>
          <td className="font-medium">
            {getResolutionContent(
              row?.conditions?.resolution,
              row?.artwork?.metadata?.assetFileFormat ?? "",
              assetDetails?.width,
              assetDetails?.height,
              assetDetails?.dpi,
            )}

            {/* {assetDetails?.width} x {assetDetails?.height} px, {assetDetails?.dpi} dpi */}
          </td>
        </tr>
        <tr>
          <td>File size on download</td>
          <td className="font-medium">{humanFileSize(assetDetails?.fileSize, true)}</td>
        </tr>
        <tr>
          <td>Accession number</td>
          <td className="font-medium">{row?.artwork?.importRef?.value}</td>
        </tr>
        <tr>
          <td>Territory of use</td>
          <td className="font-medium">
            {startCase(row?.conditions?.territory)}
            {startCase(row?.conditions?.territory) === "Single" ? " territory" : ""}
          </td>
        </tr>
        <tr>
          <td>Usage</td>
          <td className="font-medium">{startCase(row?.conditions?.usage)} use</td>
        </tr>
        <tr>
          <td>Duration</td>
          <td className="font-medium">
            {`${Duration.fromObject({
              milliseconds: row?.conditions?.duration,
            }).as("years")} Year` || "1 Year"}
          </td>
        </tr>

        {showMetaData && (
          <>
            <tr>
              <td>Project title/description</td>
              <td className="font-medium">{startCase(row?.details?.title)}</td>
            </tr>
            <tr>
              <td>Project creator/author</td>
              <td className="font-medium">{startCase(row?.details?.creator)}</td>
            </tr>
            <tr>
              <td>Format of reproduction</td>
              <td className="font-medium">
                {startCase(row?.details?.formatOfReproduction) || "-"}
              </td>
            </tr>
            <tr>
              <td>Language of use</td>
              <td className="font-medium">{startCase(row?.details?.language) || "-"}</td>
            </tr>
            <tr>
              <td>Publisher</td>
              <td className="font-medium">{startCase(row?.details?.publisher) || "-"}</td>
            </tr>
            <tr>
              <td>Use of image</td>
              <td className="font-medium">{startCase(row?.details?.useOfImage) || "-"}</td>
            </tr>
            <tr>
              <td>Print run</td>
              <td className="font-medium">{startCase(row?.details?.printRun) || "-"}</td>
            </tr>
            <tr>
              <td>Retail price</td>
              <td className="font-medium">{row?.details?.retailPrice?.toFixed(2) || "-"}</td>
            </tr>
            <tr>
              <td>Remarks</td>
              <td className="font-medium">{startCase(row?.details?.remark) || "-"}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default RequestMetadata;
