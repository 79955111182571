import { clsMerge } from "@artifactlabs/shared-react-utils";
import { inRange, isSafeInteger } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

import NextButton from "@/components/Button/NextButton";
import PreviousButton from "@/components/Button/PreviousButton";

interface PageSelectorProps {
  loading: boolean;
  currentPage: number;
  totalPages: number;
}

const PageSelector: React.FC<PageSelectorProps> = ({ loading, currentPage, totalPages }) => {
  const router = useRouter();
  const [targetPage, setTargetPage] = useState<number>(currentPage);
  const [error, setError] = useState<string>("");

  const isFirstPage = useMemo(() => currentPage === 1, [currentPage]);
  const isLastPage = useMemo(
    () => totalPages === 0 || currentPage === totalPages,
    [currentPage, totalPages],
  );

  useEffect(() => {
    setTargetPage(currentPage);
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    if (isSafeInteger(page) && inRange(page, 1, totalPages + 1)) {
      setError("");
      const newQuery = { ...router.query, page: page };
      router.push({
        pathname: router.pathname,
        query: newQuery,
      });
    } else {
      setError(`Invalid page number`);
    }
  };

  return (
    <div className="mx-auto w-[364px] py-10">
      <div className="flex flex-1 justify-between ">
        <PreviousButton
          className={clsMerge(isFirstPage && "invisible")}
          disabled={loading}
          href={{
            pathname: router.pathname,
            query: { ...router.query, page: Math.max(currentPage - 1, 1) },
          }}
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
        />
        <div className="text-sm font-normal leading-tight text-neutral-700">{targetPage}</div>
        <NextButton
          className={clsMerge(isLastPage && "invisible")}
          disabled={loading}
          href={{
            pathname: router.pathname,
            query: { ...router.query, page: Math.min(currentPage + 1, totalPages) },
          }}
          onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
        />
      </div>
      <div className="mt-4 text-center text-xs text-[#E51921]">{error}</div>
    </div>
  );
};

export default PageSelector;
