import { Request } from "@/gql/graphql";

type FormattedRequest = {
  select: boolean;
  id: string;
  useType: string;
  price: number;
  createdAt: string;
  tier: string;
  publisher: string;
  artwork: any;
  requester: {
    user: {
      name: string;
      entityName: string;
    };
  };
  version: number;
};

// TODO: useTypeDisplayKeyValueMap should move to shared-utils
export const useTypeDisplayKeyValueMap: {
  [key: string]: string;
} = {
  publication: "Publication",
  "education/research": "Education/Research",
  digital: "Digital",
  broadcast: "Broadcast",
  exhibition: "Exhibition",
  "government/public_policy": "Government/Public policy",
  personal: "Personal",
  editorial: "Editorial",
  commercial: "Commercial",
  custom: "Custom",
  documentary: "documentary",
  commercial_exhibition: "Commercial - exhibition",
  non_profit_exhibition: "Non-profit - exhibition",
  commercial_above_the_line: "Commercial - Above the line",
  commercial_digital: "Commercial - digital",
  commercial_internal_use: "Commercial - internal use",
  commercial_marketing_collaterals: "Commercial - marketing collaterals",
  commercial_print: "Commercial - print",
  commercial_print_digital: "Commercial - print + digital",
  course_material_digital: "Course material - digital",
  course_material_print: "Course material - print",
  course_material_print_digital: "Course material - print + digital",
  editorial_use_broadcast: "Editorial use - Broadcast",
  editorial_use_digital: "Editorial use - digital",
  editorial_use_print: "Editorial use - print",
  editorial_use_print_digital: "Editorial use - print + digital",
  non_profit_digital: "Non-profit - digital",
  non_profit_internal_use: "Non-profit - internal use",
  non_profit_print: "Non-profit - print",
  non_profit_print_digital: "Non-profit - print + digital",
  other_educational_research: "Other Educational / Research",
};

export const useTypeDisplayKeyValueMap_SNM: {
  [key: string]: string;
} = {
  personal: "Order reproduction graphic/historical digital image",
  editorial: "Order object photography digital image",
  commercial: "Other requests",
  custom: "Custom",
};

class RequestAdapter {
  private requests: Array<Request>;
  private tenant: any;

  constructor(requests?: Array<any>, tenant?: any) {
    this.requests = requests ?? [];
    this.tenant = tenant ?? [];
  }

  getRequestList(): Array<any> {
    let translator: { [key: string]: string } = {};
    switch (this.tenant.id) {
      case "snm": {
        translator = useTypeDisplayKeyValueMap_SNM;
        break;
      }
      default: {
        translator = useTypeDisplayKeyValueMap;
        break;
      }
    }
    const formattedRequest = this.requests?.map(each => ({
      creator: each?.details?.creator || "",
      id: each.extId || "",
      useType: translator[(each?.conditions?.use || "")?.toLowerCase()] || "",
      price: each.price || 0,
      createdAt: each.createdAt || "",
      tier: each?.creatorProfile?.userTier || "BASIC",
      artwork: each.artwork,
      details: each.details,
      conditions: each.conditions,
      requester: {
        user: {
          name: `${each?.creatorProfile?.firstName} ${each?.creatorProfile?.lastName}`,
          entityName: each?.creatorProfile?.entity?.name,
        },
      },
      version: each.version || 0,
    }));

    return formattedRequest;
  }
}

export type { FormattedRequest };
export default RequestAdapter;
