/*
  By wrapping the Next.js Link component in this AppLink component,
  we can easily customize its behavior and centralize the routing path logic,
  while maintaining compatibility with future upgrades of Next.js.

  Example usage:
  import AppLink from './AppLink';
  import { routes } from "@/utils/routes";

  <AppLink href={routes.search(1, "image")}>
    About
  </AppLink>
*/

import { clsMerge } from "@artifactlabs/shared-react-utils";
import Link, { LinkProps } from "next/link";
import { HTMLAttributes, forwardRef } from "react";
import { UrlObject } from "url";

interface AppLinkProps extends LinkProps, HTMLAttributes<HTMLAnchorElement> {
  href: string | UrlObject;
  disabled?: boolean;
}

const AppLink = forwardRef(
  ({ href, children, disabled = false, className, ...rest }: AppLinkProps, ref: any) => {
    return (
      <Link
        className={clsMerge(className, disabled ? "pointer-events-none" : "pointer-events-auto")}
        href={href}
        ref={ref}
        {...rest}
      >
        {children}
      </Link>
    );
  },
);

AppLink.displayName = "AppLink";

export type { AppLinkProps };
export default AppLink;
