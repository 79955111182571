import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent } from "react";

import AppLink, { AppLinkProps } from "@/components/AppLink";
import ChevronArrowRight from "@/components/Icons/ChevronArrowRight";

const NextButton: FunctionComponent<AppLinkProps> = ({ href = "#", className, ...rest }) => {
  return (
    <AppLink
      className={clsMerge(
        "relative ml-3 inline-flex items-center gap-2 rounded-[4px] bg-white py-[10px] pl-4 pr-3",
        "text-sm font-medium leading-tight  text-[#1C2442]",
        "ring-1 ring-inset ring-[#1C2442] hover:bg-gray-50 focus-visible:outline-offset-0",
        className,
      )}
      href={href}
      {...rest}
    >
      Next
      <ChevronArrowRight className="h-4 w-4 stroke-[2px] text-[#1C2442]" />
    </AppLink>
  );
};

export default NextButton;
